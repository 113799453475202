<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>
        mdi-vector-polyline
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle
        class="primary--text
          "
      >
        {{ station.name }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-progress-circular
        indeterminate
        color="accent"
        v-if="dataLoading"
      ></v-progress-circular>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            v-on="on"
            color="accent"
            @click="getTransectData()"
            :disabled="plotDisabled"
          >
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("transectPlot") }}</span>
      </v-tooltip>
    </v-list-item-action>
    <transect-plot
      :show-dialog.sync="showDialog"
      :station="station"
    ></transect-plot>
  </v-list-item>
</template>

<script>
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import TransectPlot from "@/core/components/plot/TransectPlot";
import { mapActions, mapState } from "vuex";

export default {
  name: "VirtualStationLine",
  components: { TransectPlot },
  mixins: [virtualStationMixin],
  props: {
    station: Object
  },
  data: () => ({
    dataLoading: false,
    plotDisabled: false,
    showDialog: false
  }),
  computed: {
    ...mapState("raster", ["activeRasterLayer"]),
    ...mapState("plot", ["dataCache"])
  },
  methods: {
    ...mapActions("plot", [
      "setShowTransectPlot",
      "setLineStation",
      "resetDataCache"
    ]),
    getTransectData() {
      // this.showDialog = true;
      if (this.dataCache.length) {
        this.resetDataCache();
      }
      this.setShowTransectPlot(true);
      this.setLineStation(this.station);
    }
  }
};
</script>

<style scoped></style>
