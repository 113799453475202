import Vue from "vue";
import axios from "axios";

const trackAxios = axios.create();

const isEomapUser = () => {
  const hasEomapRole = Vue.prototype.$keycloak.realmAccess?.roles.includes(
    "eomap"
  );
  return hasEomapRole;
};

const getDefaultTrackData = (url, title) => {
  return {
    payload: {
      hostname: window.location.hostname,
      language: navigator.language,
      referrer: document.referrer,
      screen: `${window.screen.width}x${window.screen.height}`,
      title: title,
      url: url,
      website: Vue.prototype.$appConfig.trackingID
    },
    type: "event"
  };
};

export const trackUrl = to => {
  const trackingId = Vue.prototype.$appConfig?.trackingID;
  const trackingDomains = Vue.prototype.$appConfig?.trackingDomains;
  const trackData = getDefaultTrackData(to.matched[0].path, String(to.name));

  if (
    trackingId &&
    trackingDomains.includes(trackData.payload.hostname) &&
    !isEomapUser()
  ) {
    trackAxios.post("https://analytics.eomap.com/api/send", trackData);
  }
};

export const trackEvent = (eventName, route, eventData) => {
  const trackingId = Vue.prototype.$appConfig?.trackingID;
  const trackingDomains = Vue.prototype.$appConfig?.trackingDomains;

  const trackData = getDefaultTrackData(
    route.matched[0].path,
    String(route.name)
  );
  trackData.payload.name = eventName;
  trackData.payload.data = eventData;

  if (
    trackingId &&
    trackingDomains.includes(trackData.payload.hostname) &&
    !isEomapUser()
  ) {
    trackAxios.post("https://analytics.eomap.com/api/send", trackData);
  }
};
