<template>
  <v-card>
    <v-btn
      fab
      absolute
      top
      right
      small
      text
      class="mt-5"
      style="right: 0"
      @click="close"
    >
      <v-icon small>
        mdi-close
      </v-icon>
    </v-btn>
    <v-list>
      <v-list-item v-if="displayRasterItem">
        <v-list-item-avatar>
          <v-icon>
            mdi-layers
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> {{ activeRasterLayer.title }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ displayActiveTimestep }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-subheader v-text="displayCoordinates" />
      </v-list-item>
      <raster-item
        class="ml-5"
        :coordinates="coordinates"
        v-if="displayRasterItem"
      />
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="displayRasterItem"
            class="ml-16 pl-6 py-0"
            color="accent"
            @click="setPopupAsVirtualStation"
            :disabled="stationSet"
            text
          >
            {{ $t("setStation") }}
          </v-btn>
        </template>
        <span>{{ $t("setPointStation") }}</span>
      </v-tooltip>
      <virtual-station-item
        class="ml-5"
        :coordinates="coordinates"
        :close="close"
      />
      <transect-item class="ml-5" :coordinates="coordinates" />
    </v-list>
    <v-divider class="pb-2 ml-5 mr-10"></v-divider>
    <vector-item :coordinates="coordinates" />
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import RasterItem from "@/core/components/map/pop-up/RasterItem";
import VectorItem from "@/core/components/map/pop-up/VectorItem";
import VirtualStationItem from "@/core/components/map/pop-up/VirtualStationItem";
import TransectItem from "@/core/components/map/pop-up/TransectItem";

export default {
  name: "PopUp",
  components: { RasterItem, VectorItem, VirtualStationItem, TransectItem },
  props: ["close", "coordinates", "pixelCoordinates"],
  data: () => ({
    stationSet: false
  }),
  computed: {
    ...mapState("vector", ["activeVectorLayer"]),
    ...mapState("raster", ["activeRasterLayer"]),
    ...mapGetters("plot", ["disabledPlotButton"]),
    displayCoordinates() {
      return `LAT: ${this.coordinates.lat.toFixed(
        4
      )}, LON: ${this.coordinates.lng.toFixed(4)}`;
    },
    displayRasterItem() {
      return this.activeRasterLayer?.layerId;
    },
    displayActiveTimestep() {
      return this.activeRasterLayer.datetime.replace("T", " ");
    }
  },
  methods: {
    ...mapActions("draw", ["addVirtualStation"]),
    ...mapActions("plot", ["setShowFullSizePlot"]),
    setPopupAsVirtualStation() {
      const lon = this.coordinates.lng;
      const lat = this.coordinates.lat;
      const geometry = { type: "Point", coordinates: [lon, lat] };
      const featureId = this.$store.draw.add(geometry);
      const feature = this.$store.draw.get(featureId[0]);
      this.addVirtualStation(feature); // TODO change id to feature
      this.stationSet = true;
    }
  }
};
</script>

<style>
.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

.mapboxgl-popup {
  max-width: 400px !important;
}
</style>
