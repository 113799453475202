<template>
  <v-card>
    <v-card-title class="pb-0">{{ $t("defineName") }}</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="name"
        placeholder="Name"
        hide-details
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancel">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn color="primary" text @click="save">
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import virtualStation from "@/core/services/virtualStation.services";

export default {
  name: "NamingPopup",
  props: ["close", "pixelCoordinates"],
  data: () => ({
    stationSet: false,
    item: null
  }),
  computed: {
    ...mapState("draw", ["newStation"])
  },
  methods: {
    ...mapActions("draw", [
      "updateName",
      "setVSPermanent",
      "deleteVirtualStation"
    ]),
    ...mapActions("app", ["showSnackbar"]),
    cancel() {
      this.$store.draw.delete(this.item.id);
      this.deleteVirtualStation(this.item.id);
      this.close();
    },
    save() {
      this.updateName({ item: this.item, name: this.name });
      this.saveItem(this.item);
      this.close();
    },
    saveItem(item) {
      if (item.permanent) {
        try {
          virtualStation.update(item.id);
          this.showSnackbar({
            show: true,
            message: this.$t("stationUpdated"),
            color: "success"
          });
        } catch (error) {
          this.showSnackbar({
            show: true,
            message: this.$t("errorMessage"),
            color: "error"
          });
        }
      } else {
        try {
          virtualStation.save(item.id);
          this.setVSPermanent(item.id);
          this.showSnackbar({
            show: true,
            message: this.$t("stationSaved"),
            color: "success"
          });
        } catch (error) {
          this.showSnackbar({
            show: true,
            message: this.$t("errorMessage"),
            color: "error"
          });
        }
      }
    }
  },
  created() {
    this.item = this.newStation;
    this.name = this.newStation.name;
  }
};
</script>

<style>
.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

.mapboxgl-popup {
  max-width: 400px !important;
}
</style>
