import mapboxgl from "mapbox-gl";
import Vue from "vue";
import vuetify from "@/core/plugins/vuetify";
import store from "@/core/store";
import i18n from "@/localization/i18n";
import PopUp from "@/core/components/map/PopUp";
import NamingPopup from "@/core/components/map/NamingPopup.vue";
import ValuePopup from "@/core/components/map/ValuePopup.vue";
import router from "@/core/router";

const VuePopupComponent = Vue.extend(PopUp);
const VueNamingPopupComponent = Vue.extend(NamingPopup);
const VueValuePopupComponent = Vue.extend(ValuePopup);

let popUpMixin = {
  data: () => ({
    coordinates: {},
    pixelCoordinates: {},
    popUpRefs: [],
    namingPopUpRefs: [],
    valuePopUpRefs: []
  }),
  popUp: null, // Needs to be a non reactive variable, otherwise mapbox crashes -> not in data
  namingPopUp: null, // Needs to be a non reactive variable, otherwise mapbox crashes -> not in data
  valuePopUp: null, // Needs to be a non reactive variable, otherwise mapbox crashes -> not in data
  methods: {
    showNamingPopup(event) {
      let map = this.$store.visualizeMap;
      event = this.editCoordinateStructure(event);
      window.setTimeout(() => {
        // new timeout to open popup after drawing
        let popUpObject = (window.popup = new mapboxgl.Popup()
          .setLngLat(event.lngLat)
          .setHTML('<div id="vue-naming-popup-content"></div>')
          .addTo(map));
        this.$options.popUp = popUpObject;
        popUpObject.on("close", () => {
          if (this.namingPopUpRefs.length === 2) {
            this.namingPopUpRefs.shift().$destroy();
          }
        });
        this.createVueNamingPopup(popUpObject);
      }, 0);
    },
    showPopup(event) {
      if (this.popUpRefs.length === 1) {
        this.popUpRefs[0].close();
      }
      let map = this.$store.visualizeMap;
      this.coordinates = event.lngLat;
      this.pixelCoordinates = event.point;

      let popUpObject = new mapboxgl.Popup()
        .setLngLat(this.coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .addTo(map);
      this.$options.popUp = popUpObject;
      popUpObject.on("close", () => {
        if (this.popUpRefs.length === 2) {
          this.popUpRefs.shift().$destroy();
        }
      });
      this.createVuePopup(popUpObject);
    },
    showValuePopup(
      event,
      map,
      selectedLayer,
      timeLayer,
      itemsLayer,
      container
    ) {
      this.coordinates = event.lngLat;
      this.pixelCoordinates = event.point;

      const popUpObject = new mapboxgl.Popup()
        .setLngLat(this.coordinates)
        .setHTML('<div id="vue-value-popup-content"></div>')
        .addTo(map);
      this.$options.valuePopUp = popUpObject;
      popUpObject.on("close", () => {
        if (this.valuePopUpRefs.length === 2) {
          this.valuePopUpRefs.shift().$destroy();
        }
      });
      this.createVueValuePopup(
        popUpObject,
        selectedLayer,
        timeLayer,
        itemsLayer,
        container
      );
    },
    editCoordinateStructure(event) {
      if (event.features[0].geometry.type == "Polygon") {
        event.lngLat = [
          event.features[0].geometry.coordinates[0][0][0],
          event.features[0].geometry.coordinates[0][0][1]
        ];
      } else if (event.features[0].geometry.type == "LineString") {
        event.lngLat = [
          event.features[0].geometry.coordinates[0][0],
          event.features[0].geometry.coordinates[0][1]
        ];
      } else {
        event.lngLat = [
          event.features[0].geometry.coordinates[0],
          event.features[0].geometry.coordinates[1]
        ];
      }

      return event;
    },
    closeAndDestroy(popUp) {
      popUp.remove();
      this.popUpRefs.shift().$destroy();
    },
    closeAndDestroyNaming(popUp) {
      popUp.remove();
    },
    closeAndDestroyValue(popUp) {
      popUp.remove();
      this.valuePopUpRefs.shift().$destroy();
    },
    createVuePopup(popUpObject) {
      const ref = new VuePopupComponent({
        el: "#vue-popup-content",
        store,
        i18n,
        vuetify,
        router,
        propsData: {
          close: () => this.closeAndDestroy(popUpObject),
          coordinates: this.coordinates,
          pixelCoordinates: this.pixelCoordinates
        }
      });
      popUpObject._update();
      this.popUpRefs.push(ref);
    },
    createVueNamingPopup(popUpObject) {
      const ref = new VueNamingPopupComponent({
        el: "#vue-naming-popup-content",
        store,
        i18n,
        vuetify,
        router,
        propsData: {
          close: () => this.closeAndDestroyNaming(popUpObject)
        }
      });
      popUpObject._update();
      this.namingPopUpRefs.push(ref);
    },
    createVueValuePopup(popUpObject) {
      const ref = new VueValuePopupComponent({
        el: "#vue-value-popup-content",
        store,
        i18n,
        vuetify,
        router,
        propsData: {
          coordinates: this.coordinates,
          selectedLayer: this.selectedLayer,
          timeLayer: this.timeLayer,
          itemsLayer: this.itemsLayer,
          container: this.container,
          close: () => this.closeAndDestroyValue(popUpObject)
        }
      });
      popUpObject._update();
      this.valuePopUpRefs.push(ref);
    }
  }
};

export default popUpMixin;
