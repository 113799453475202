<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>
        mdi-vector-point
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-hover
        v-slot="{ hover }"
        v-if="station.permanent && $appConfig.keycloakClient !== 'coasts'"
      >
        <v-list-item-subtitle
          class="row-pointer"
          @click="toMonitoringDetails"
          :title="$t('monitoring.toDetailsPage')"
          :class="
            hover
              ? 'primary--text font-weight-bold text-decoration-underline'
              : 'primary--text font-weight-bold '
          "
        >
          {{ station.name }}
        </v-list-item-subtitle>
      </v-hover>
      <span
        v-if="station.permanent && $appConfig.keycloakClient === 'coasts'"
        >{{ station.name }}</span
      >
      <v-list-item-subtitle
        class="primary--text font-weight-bold"
        v-if="!station.permanent"
      >
        {{ station.name }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text--primary" v-html="displayActualValue" />
    </v-list-item-content>
    <v-list-item-action
      v-if="
        !station.name.includes('Profile_') &&
          $appConfig.keycloakClient !== 'coasts'
      "
    >
      <v-progress-circular
        indeterminate
        color="accent"
        v-if="dataLoading"
      ></v-progress-circular>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            v-on="on"
            color="accent"
            @click="addTimeseries()"
            :disabled="addButtonDisabled"
          >
            <v-icon v-if="addButtonDisabled">mdi-check-bold</v-icon>
            <v-icon v-else>mdi-chart-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("addVirtualStationToPlot") }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";

export default {
  name: "VirtualStationPoint",
  mixins: [virtualStationMixin],
  props: ["station", "geometry", "activeTimestep", "close"],
  data: () => ({
    dataLoading: false
  }),
  methods: {
    async addTimeseries() {
      this.dataLoading = await this.addTimeseriesToPlot();
    },
    toMonitoringDetails() {
      const client = this.$appConfig.keycloakClient;
      this.close();
      if (client === "hypos") {
        this.$router.push({
          name: "BaselineDashboard"
        });
      } else {
        this.$router.push({
          name: "MonitoringDetails",
          query: { timestep: this.activeTimestep },
          params: {
            analysisGeometry: this.geometry
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>
