import Vue from "vue";
import axios from "axios";
import store from "@/core/store/index";

const save = async id => {
  const client = Vue.prototype.$appConfig.keycloakClient;
  const region = store.getters["management/activeRegion"].id;
  const geometry = store.draw.get(id).geometry;
  const station = store.getters["draw/stationById"](id);
  const url = "/userdata/virtual-stations/";
  const response = await axios.post(url, {
    id: id,
    name: station.name,
    client: client,
    region: region,
    geometry: geometry
  });
  return response.data;
};

const update = async id => {
  const client = Vue.prototype.$appConfig.keycloakClient;
  const region = store.getters["management/activeRegion"].id;
  const geometry = store.draw.get(id).geometry;
  const station = store.getters["draw/stationById"](id);
  const url = `/userdata/virtual-stations/${id}/`;
  const response = await axios.put(url, {
    id: id,
    name: station.name,
    client: client,
    region: region,
    geometry: geometry
  });

  return response.data;
};

const remove = async id => {
  const url = `/userdata/virtual-stations/${id}/`;
  await axios.delete(url);
};

export default { save, update, remove };
