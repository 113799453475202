<template>
  <v-list v-if="showStationFeatures">
    <v-divider class="pb-2 mr-10"></v-divider>
    <v-list-item-subtitle class="pb-0 pl-5">{{
      $t("transects")
    }}</v-list-item-subtitle>
    <template v-for="station in stationFeatures">
      <virtual-station-line
        v-if="station.type === 'LineString'"
        :station="station"
        :key="station.id"
      ></virtual-station-line>
    </template>
  </v-list>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import VirtualStationLine from "@/core/components/map/pop-up/VirtualStationLine";

export default {
  name: "TransectItem",
  components: {
    VirtualStationLine
  },
  props: ["coordinates"],
  data: () => ({
    stationFeatures: []
  }),
  computed: {
    ...mapState("draw", ["virtualStations"]),
    ...mapState("raster", ["activeRasterLayer"]),
    ...mapGetters("draw", ["stationById"]),
    showStationFeatures() {
      return !!this.stationFeatures.length;
    }
  },
  methods: {
    async queryStationFeatures() {
      const map = this.$store.visualizeMap;
      const pixelCoordinates = map.project(this.coordinates);
      let layers = [];
      layers.push("gl-draw-line-static.cold");
      layers.push("gl-draw-line-static.hot");

      let features = [];
      features = map.queryRenderedFeatures(pixelCoordinates, {
        layers: layers
      });

      this.stationFeatures = features.map(feature => {
        let stationCopy = { ...this.stationById(feature.properties.id) };
        stationCopy.type = feature.properties["meta:type"];
        return stationCopy;
      });
    }
  },
  created() {
    this.queryStationFeatures();
  },
  watch: {
    virtualStations() {
      setTimeout(this.queryStationFeatures, 100);
    }
  }
};
</script>

<style scoped></style>
