<template>
  <v-dialog
    v-model="showDialog"
    @click:outside="close"
    width="auto"
    max-width="1000"
  >
    <v-card>
      <v-btn absolute right top icon @click="close" style="z-index: 1">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        {{ $t("transect.title") }} {{ activeRasterLayer.title }}
      </v-card-title>
      <v-card-text v-if="showPlot">
        <v-select
          v-model="selectedDatetimes"
          :items="layerTimesteps"
          item-value="datetime"
          :item-text="datetimeFormat"
          chips
          clearable
          :label="$t('datetime')"
          multiple
          @input="toggleDateEntry"
        ></v-select>
        <plotly :data="data" :layout="layout"></plotly>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="primary" @click="exportData"
          >{{ $t("exportPlotData") }}
        </v-btn>
        <v-spacer />
        <v-btn color="primary" @click="close">
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { Plotly } from "vue-plotly";

export default {
  name: "TransectPlot",
  components: { Plotly },
  props: {
    showDialog: {
      default: false
    },
    station: Object
  },
  data: () => ({
    fullscreen: false,
    showPlot: false,
    data: [],
    selectedDatetimes: []
  }),
  computed: {
    ...mapState("raster", ["layerTimesteps", "activeRasterLayer"]),
    ...mapState("management", ["accessToken"]),
    layout() {
      return {
        showLegend: true,
        height: 500,
        width: 950,
        xaxis: {
          title: {
            text: this.$t("transect.meter")
          }
        },
        yaxis: {
          title: {
            text: this.activeRasterLayer.unit
          }
        }
      };
    },
    plotEntries() {
      return this.data.map(x => x.id);
    }
  },
  methods: {
    close() {
      this.$emit("update:showDialog", false);
    },
    datetimeFormat: item => item.datetime.replace("T", " "),
    async fetchTransect(datetime) {
      const response = await this.$rastless.post(
        `/layers/${this.activeRasterLayer.layerId}/${datetime}/transect?token=${this.accessToken}`,
        this.station.geometry
      );
      const data = response.data;

      for (const [key, values] of Object.entries(data.data)) {
        const dataEntry = {
          id: datetime,
          name: `${datetime.replace("T", " ")} ${key}`,
          x: data.cumulated_length,
          y: values,
          coords: data.coordinates,
          showlegend: true
        };
        this.data.push(dataEntry);
      }
    },
    toggleDateEntry(entry) {
      const dateEntriesToAdd = entry.filter(x => !this.plotEntries.includes(x));
      dateEntriesToAdd.forEach(datetime => this.fetchTransect(datetime));

      const dateEntriesToRemove = this.plotEntries.filter(
        x => !entry.includes(x)
      );
      dateEntriesToRemove.forEach(datetime => this.deletePlotEntry(datetime));
    },
    deletePlotEntry(datetime) {
      this.data = this.data.filter(plotEntry => plotEntry.id !== datetime);
    },
    exportData() {
      const csvContent = this.createCsvContent();
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "transect.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    createCsvContent() {
      const heading = this.data.map(entry => entry.name);
      heading.unshift("Length [m]", "Lat", "Lon");

      let rows = [heading];

      const numberOfEntriesIndexes = [...Array(this.data.length).keys()];

      this.data[0].x.forEach((lengthInMeters, lengthIndex) => {
        const row = numberOfEntriesIndexes.map(
          layerIndex => this.data[layerIndex].y[lengthIndex] || ""
        );
        row.unshift(
          lengthInMeters,
          this.data[0].coords[lengthIndex][1],
          this.data[0].coords[lengthIndex][0]
        );
        rows.push(row);
      });

      return (
        "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n")
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.showPlot = true;
    });
  },
  watch: {
    showDialog: {
      handler(show) {
        if (show) {
          const datetime = this.activeRasterLayer.datetime;
          this.selectedDatetimes = [];
          this.selectedDatetimes.push(datetime);
          this.fetchTransect(datetime);
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
