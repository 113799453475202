<template>
  <v-card v-if="displayValuePopup">
    <v-btn
      fab
      absolute
      top
      right
      text
      x-small
      plain
      :ripple="false"
      style="right: 0; top: 0"
      @click="close"
    >
      <v-icon x-small>
        mdi-close
      </v-icon>
    </v-btn>
    <v-card-text class="text--primary" style="padding-top: 20px">
      <v-progress-circular
        size="20"
        indeterminate
        v-if="actualValueLoading"
      ></v-progress-circular>
      <span v-html="displayActualValue" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ValuePopUp",
  props: {
    container: String,
    coordinates: Object,
    itemsLayer: Array,
    selectedLayer: String,
    timeLayer: String,
    close: Function
  },
  data: () => ({
    actualValue: {},
    actualValueLoading: false
  }),
  computed: {
    ...mapState("management", ["accessToken"]),
    ...mapGetters("valuePopup", [
      "getSelectedLayerTopMap",
      "getSelectedLayerBottomMap",
      "getTimeLayer"
    ]),
    displayValuePopup() {
      return this.actualValue;
    },
    displayActualValue() {
      if (this.actualValueLoading) {
        return "";
      }
      let hasValidValue = false;
      const unit = this.getUnitFromLayedId();
      const bandsFormatted = Object.keys(this.actualValue).map(band => {
        const value = this.actualValue[band];
        if (value !== null && !isNaN(value)) {
          hasValidValue = true;
          return `<b>${value} ${unit}</b>`;
        }
      });
      if (!hasValidValue) {
        return `<b>${this.$t("noData")}</b>`;
      }
      return bandsFormatted.length
        ? bandsFormatted.join(", ")
        : "<span style='color: white'>|</span>";
    }
  },
  methods: {
    getUnitFromLayedId() {
      const layer = this.itemsLayer.filter(
        item => item.layerId === this.selectedLayer
      );
      if (layer[0].unit !== null) {
        return layer[0].unit;
      } else {
        return "";
      }
    },
    async fetchActualValue() {
      this.actualValueLoading = true;
      this.actualValue = {};
      if (this.selectedLayer) {
        const response = await this.$rastless.post(
          `layers/${this.selectedLayer}/${this.timeLayer}/statistic?token=${this.accessToken}`,
          {
            geometry: {
              type: "Point",
              coordinates: [this.coordinates.lng, this.coordinates.lat]
            }
          }
        );
        this.actualValue = response.data.data;
        this.actualValueLoading = false;
      }
    }
  },
  created() {
    this.fetchActualValue();
  },
  watch: {
    getSelectedLayerTopMap(newVal) {
      if (this.container === "topMap") {
        this.selectedLayer = newVal;
        this.fetchActualValue();
      }
    },
    getSelectedLayerBottomMap(newVal) {
      if (this.container === "bottomMap") {
        this.selectedLayer = newVal;
        this.fetchActualValue();
      }
    },
    getTimeLayer(newVal) {
      this.timeLayer = newVal;
      this.fetchActualValue();
    }
  }
};
</script>

<style scoped></style>
