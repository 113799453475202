<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon>
        mdi-crosshairs-gps
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-subtitle
        class="primary--text text--darken-2
          "
      >
        {{ displayCoordinates }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text--primary">
        <v-progress-circular
          size="20"
          indeterminate
          v-if="actualValueLoading"
        ></v-progress-circular>
        <span v-html="displayActualValue" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-progress-circular
        indeterminate
        color="accent"
        v-if="dataLoading"
      ></v-progress-circular>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            v-on="on"
            color="accent"
            @click="addTimeseriesToPlot"
            :disabled="addButtonDisabled"
          >
            <v-icon v-if="addButtonDisabled">mdi-check-bold</v-icon>
            <v-icon v-else>mdi-chart-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("addToPlot") }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PlotUtils from "@/core/utils/plot.utils";
import moment from "moment/moment";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import { trackEvent } from "@/core/plugins/analytics";

export default {
  name: "RasterItem",
  props: ["coordinates"],
  mixins: [virtualStationMixin, rasterLayerMixin],
  data: () => ({
    actualValue: {},
    dataLoading: false,
    addButtonClicked: false,
    actualValueLoading: false,
    datetimeSelection: []
  }),
  computed: {
    ...mapState("management", [
      "accessToken",
      "tempResolution",
      "aggregationStep"
    ]),
    ...mapState("raster", [
      "activeRasterLayer",
      "layerTimesteps",
      "tempResLayerTimesteps",
      "filteredDatetimeSelection"
    ]),
    ...mapGetters("plot", ["disabledPlotButton", "dataCachePlotIds"]),
    displayActualValue() {
      const unit = this.activeRasterLayer.unit
        ? this.activeRasterLayer.unit
        : "";
      const bandsFormatted = Object.keys(this.actualValue).map(band => {
        const value = this.actualValue[band];
        return value !== null && !isNaN(value)
          ? `<b>${value} ${unit}</b>`
          : `<b>${this.$t("noData")}</b>`;
      });
      return bandsFormatted.length
        ? bandsFormatted.join(", ")
        : "<span style='color: white'>|</span>";
    },
    displayCoordinates() {
      return `LAT: ${this.coordinates.lat.toFixed(
        4
      )}, LON: ${this.coordinates.lng.toFixed(4)}`;
    },
    displayActiveTimestep() {
      return this.activeRasterLayer.datetime.replace("T", " ");
    },
    addButtonDisabled: {
      get() {
        return this.addButtonClicked && !this.disabledPlotButton;
      },
      set() {
        this.addButtonClicked = true;
      }
    },
    plotId() {
      return `${this.activeRasterLayer.layerId}_${this.coordinates.lat}_${this.coordinates.lng}_${this.tempResolution}`;
    }
  },
  methods: {
    ...mapActions("plot", [
      "addDataEntry",
      "deleteDataEntry",
      "setShowPlotFeature",
      "setShowPlot"
    ]),
    async fetchActualValue() {
      this.actualValueLoading = true;
      this.actualValue = {};
      if (this.activeRasterLayer?.sensor) {
        const response = await this.$rastless.post(
          `layers/${this.activeRasterLayer.layerId}/${this.activeRasterLayer.datetime}/statistic?token=${this.accessToken}`,
          {
            geometry: {
              type: "Point",
              coordinates: [this.coordinates.lng, this.coordinates.lat]
            }
          }
        );
        this.actualValue = response.data.data;
        this.actualValueLoading = false;
      } else {
        const dates = this.defineDateRange();
        if (dates !== null) {
          const response = await this.$rastless.post(
            `layers/${this.activeRasterLayer.layerId}/statistic?start_date=${dates.startDate}&end_date=${dates.endDate}&token=${this.accessToken}`,
            {
              geometry: {
                type: "Point",
                coordinates: [this.coordinates.lng, this.coordinates.lat]
              }
            }
          );
          if (response.data?.datetime.length) {
            this.actualValue = {
              B1: this.calculateMedian(Object.values(response.data.data)[0])
            };
          } else {
            this.actualValue = 0;
          }
          this.actualValueLoading = false;
        } else {
          this.fetchTimeseriesLocal().then(data => {
            this.actualValue = {
              B1: this.calculateMedian(this.filterTimeseries(data))
            };
            this.actualValueLoading = false;
          });
        }
      }
    },
    defineDateRange() {
      if (this.tempResolution == "daily") {
        return {
          startDate: `${this.activeRasterLayer.datetime}T00:00:00`,
          endDate: `${this.activeRasterLayer.datetime}T23:59:59`
        };
      } else if (this.tempResolution == "monthly") {
        return {
          startDate: `${this.activeRasterLayer.datetime}-01T00:00:00`,
          endDate: `${this.activeRasterLayer.datetime}-31T23:59:59`
        };
      } else if (this.tempResolution == "custom") {
        const startDate = moment(this.activeRasterLayer.datetime)
          .subtract(this.aggregationStep, "d")
          .format("YYYY-MM-DD");
        return {
          startDate: `${startDate}-01T00:00:00`,
          endDate: `${this.activeRasterLayer.datetime}-31T23:59:59`
        };
      }
    },
    filterTimeseries(data, date = this.activeRasterLayer.datetime) {
      let filteredTimeseries = [];
      if (this.tempResolution == "daily") {
        data.datetime.forEach((datetime, index) => {
          if (datetime.split("T")[0] == date) {
            filteredTimeseries.push(data.data.B1[index]);
          }
        });
      } else if (this.tempResolution == "monthly") {
        data.datetime.forEach((datetime, index) => {
          const str = datetime.split("-");
          if (str[0] + "-" + str[1] == date) {
            filteredTimeseries.push(data.data.B1[index]);
          }
        });
      } else {
        let start = moment(date, "YYYY-MM-DD").subtract(
          this.aggregationStep,
          "days"
        );
        let end = moment(date, "YYYY-MM-DD").add(this.aggregationStep, "days");
        data.datetime.forEach((datetime, index) => {
          if (
            start <= moment(datetime.split("T")[0], "YYYY-MM-DD") &&
            moment(datetime.split("T")[0], "YYYY-MM-DD") <= end
          ) {
            filteredTimeseries.push(data.data.B1[index]);
          }
        });
      }
      return filteredTimeseries;
    },

    calculateMedian(arr) {
      const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
      return (arr.length % 2 !== 0
        ? nums[mid]
        : (nums[mid - 1] + nums[mid]) / 2
      ).toFixed(2);
    },

    async fetchTimeseriesLocal() {
      const stepRange = await this.setDateRange();
      let daysBack = 500;
      let endDate = moment(stepRange.last_step).add(1, "d");
      let startDate = moment(endDate)
        .subtract(daysBack, "d")
        .format("YYYY-MM-DD");

      let datetime = [];
      let values = [];
      const isEndDateValid = endDate.isValid();
      if (moment(startDate) < moment(stepRange.first_step)) {
        let response = await this.$rastless.post(
          `/layers/${this.activeRasterLayer.layerId}/statistic?token=${this.accessToken}`,
          {
            geometry: {
              type: "Point",
              coordinates: [this.coordinates.lng, this.coordinates.lat]
            }
          }
        );
        datetime = datetime.concat(response.data.datetime);
        values = values.concat(Object.values(response.data.data)[0]);
      } else {
        let requests = [];
        requests.push(
          this.$rastless
            .post(
              `/layers/${this.activeRasterLayer.layerId}/statistic?start_date=${startDate}&end_date=${endDate}&token=${this.accessToken}`,
              {
                geometry: {
                  type: "Point",
                  coordinates: [this.coordinates.lng, this.coordinates.lat]
                }
              }
            )
            .then(async response => {
              datetime = datetime.concat(response.data.datetime);
              values = values.concat(Object.values(response.data.data)[0]);
            })
        );
        while (moment(startDate) >= moment(stepRange.first_step)) {
          endDate = startDate;
          startDate = moment(endDate)
            .subtract(500, "d")
            .format("YYYY-MM-DD");
          if (isEndDateValid === true) {
            requests.push(
              this.$rastless
                .post(
                  `/layers/${this.activeRasterLayer.layerId}/statistic?start_date=${startDate}&end_date=${endDate}&token=${this.accessToken}`,
                  {
                    geometry: {
                      type: "Point",
                      coordinates: [this.coordinates.lng, this.coordinates.lat]
                    }
                  }
                )
                .then(async response => {
                  if (Object.values(response.data.data)[0]) {
                    datetime = datetime.concat(response.data.datetime);
                    values = values.concat(
                      Object.values(response.data.data)[0]
                    );
                  }
                })
            );
          }
        }
        await Promise.all(requests);
      }

      this.datetimeSelection = { datetime: [], data: { B1: [] } };
      var date = {};
      for (var i = 0, n = datetime.length; i < n; i++) {
        date[datetime[i]] = values[i];
      }
      const keys = Object.keys(date).sort();
      for (var key in keys) {
        var prop = keys[key];
        this.datetimeSelection.datetime.push(prop);
        this.datetimeSelection.data.B1.push(date[prop]);
      }

      return this.datetimeSelection;
    },

    async addTimeseriesToPlot() {
      trackEvent("fetch-timeseries", this.$route);
      this.setShowPlot(true);
      this.setShowPlotFeature(true);
      this.dataLoading = true;
      let data = [];
      if (this.datetimeSelection.length) {
        data = this.datetimeSelection;
      } else {
        data = await this.fetchTimeseriesLocal();
      }
      this.dataLoading = false;
      this.addButtonDisabled = true;
      if (this.tempResolution == "daily" || this.tempResolution == "monthly") {
        data = this.aggregateTimeseries(data);
      } else if (this.tempResolution == "custom") {
        data = this.aggregateManualTimeseries(data);
      }
      let datetimeObjects = [];
      let datetimeArray = [];
      let valuesArray = [];
      for (const [band, values] of Object.entries(data.data)) {
        data.datetime.forEach((timestep, index) => {
          if (this.filteredDatetimeSelection.length) {
            let datetimeObjectSingle = this.filteredDatetimeSelection.filter(
              step => {
                if (step?.datetime) {
                  return step.datetime === timestep;
                } else {
                  return step == timestep;
                }
              }
            );
            if (datetimeObjectSingle.length) {
              datetimeArray.push(data.datetime[index]);
              valuesArray.push(values[index]);
              datetimeObjects.push(datetimeObjectSingle[0]);
            }
          } else {
            datetimeArray.push(data.datetime[index]);
            valuesArray.push(values[index]);
          }
        });
        console.log(band);
        const legendName = PlotUtils.createRasterLegendName({
          name: this.activeRasterLayer.title,
          coordinates: this.coordinates,
          unit: this.activeRasterLayer.unit,
          tempRes: this.tempResolution,
          aggregationStep: this.aggregationStep
        });
        const filteredValues = PlotUtils.filterNullValues(
          datetimeArray,
          valuesArray,
          datetimeObjects
        );
        const plotData = PlotUtils.createPlotEntry({
          layerId: this.activeRasterLayer.layerId,
          layerType: "raster",
          x: filteredValues.x,
          y: filteredValues.y,
          steps: filteredValues.steps,
          legendName: legendName,
          unit: this.activeRasterLayer.unit,
          plotId: this.plotId
        });
        this.addDataEntry(plotData);
      }
    }
  },
  watch: {
    activeRasterLayer: {
      handler() {
        this.addButtonClicked = this.dataCachePlotIds.includes(this.plotId);
        this.fetchActualValue();
      }
    },
    tempResLayerTimesteps: {
      handler() {
        this.addButtonClicked = this.dataCachePlotIds.includes(this.plotId);
      }
    },
    immediate: true
  },
  created() {
    this.addButtonClicked = this.dataCachePlotIds.includes(this.plotId);
    this.fetchActualValue();
  }
};
</script>

<style scoped></style>
